
import React, {useState, useEffect} from "react"

import Layout from "../../layout"
import WebappsForm from "../../webapps/form"

import QRGenerator from "../../controls/qrgenerator"

const formatTools = require("../../../../lib/formatTools");
const negosyonowAPI = require("../../../../lib/requestNegosyownow")
const webappsAPI = require("../../../../lib/requestWebapps");

const ENTITYID="onsbooking"

const CUSTOMDELIVERYDROPOFFID=7; // Also used when packed

const ALLOCATEDSTATUSID=4;
const DISPATCHSTATUSID=2;
const PACKEDSTATUSID=8;


const nndropoffpickerfields = [
	{"label":"Name", "dbfield": "nndropoff_name", "type": "text", "filtertype": "textbox"},
	{"label":"Address", "dbfield": "nndropoff_strtaddress", "type": "text", "filtertype": "textbox"}
];

const nndropoffpickerfilter = [
	// N/A/Own Delivery
	//{"field":"nndropoff_id", "operation": "<>", "value": CUSTOMDELIVERYDROPOFFID}
];

const searchFields = [
	{"label":"Order Date", "dbfield": "onsbooking_datetime", "type": "datetime", "filtertype": "datetime"},
	{"label":"Order #", "dbfield": "onsorderform_code", "type": "text", "filtertype": "textbox"},
	{"label":"Seller", "dbfield": "onsbranch_name", "type": "text", "filtertype": "textbox"},
	{"label":"Seller Order #", "dbfield": "onsbooking_externcode", "type": "text", "filtertype": "textbox"},
	{"label":"Type", "dbfield": "onsorderformfulfillment_name", "type": "text", "filtertype": "textbox"},
	{"label":"Customer", "dbfield": "onscustomer_name", "type": "text", "filtertype": "textbox"},
	{"label":"Amount", "dbfield": "onsbooking_totalamount", "type": "currency", "filtertype": "currency"},
	{"label":"Status", "dbfield": "onsbookingstatus_name", "type": "text", "filtertype": "textbox"},
	{"label":"Updated", "dbfield": "onsbooking_updated", "type": "datetime", "filtertype": "datetime"}
];

const formFields = [
	[
		{"label":"Order Date", "field": "onsbooking_datetime", "value": "", "input": "datetime", "mode": "readonly"},
		{"label":"Order #", "field": "onsorderform_code", "value": "", "input": "textbox", "mode": "readonly"},
		//{"label":"Order Status ID", "field": "#onsorderformstatus_id", "value": "", "input": "hidden", "mode": "readonly"},
		{"label":"Seller", "field": "onsbranch_name", "value": "", "input": "picker", "mode": "readonly"},

		//{"label":"Customer", "field": "onscustomer_name", "value": "", "input": "picker", "mode": "readonly"},

		{"label":"Status", "field": "onsbookingstatus_id", "value": "", "input": "combo", "mode": "required", "options": [
			{"value":1,"display":"Ordered"},
			{"value":4,"display":"Allocated/Scheduled"},
			{"value":9,"display":"For scheduling (courier)"},
			{"value":6,"display":"For re-scheduling"},
			{"value":7,"display":"Accepted/For dispatching"},
			{"value":8,"display":"Packed"},
			{"value":2,"display":"Dispatched"},
			{"value":3,"display":"Completed"},
			{"value":5,"display":"Cancelled"}
		]},
		{"label":"Reason/Details", "field": "onsbooking_locationnotes", "value":"", "input": "textarea", "mode": "readonly"},

		{"label":"Last Updated", "field": "onsbooking_updated", "value": "", "input": "datetime", "mode": "readonly"},

		{"label":"Pickup ID", "field": "nnpickup_id", "value": "", "input": "hidden", "mode": "readonly"},
		{"label":"Method", "field": "onsorderformfulfillment_id", "value": "", "input": "hidden", "mode": "readonly"},

		//{"label":"Delivery Address", "field": "onsaddress_name", "value": "", "input": "picker", "mode": "readonly"},


		{"label":"Drop off Location", "field": "nndropoff_name", "value": "", "input": "picker", "mode": "normal", "pickerfields": nndropoffpickerfields, "pickerfilters": nndropoffpickerfilter /*, "info":"Set to N/A if directly deliver to customer" */},
		{"label":"Target Date", "field": "onsbooking_targetdropoffdate", "value": "", "input": "date", "mode": "normal", "info": "Date when package(s) will be at drop off location"},

	]
];


const subformFields = [
	{
		"subformid": "packages",
		"label": "Packaging",
		"table": "onsbookingpackage",
		"sort": "onsbookingpackage_id",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 5,
		"allowdel": false,
		"fieldlist": [
			{
				"label": "Package #",
				"field": "onsbookingpackage_packagenum",
				"subtotalid": "",
				"value": "",
				"input": "integer",
				"mode": "readonly"
			},
			{
				"label": "Description/Content",
				"field": "onsbookingpackage_name",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "required"
			},
			{
				"label": "QR",
				"field": "onsbookingpackage_qrcode",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "readonly"
			},
			{
				"label": "Date Created",
				"field": "onsbookingpackage_created",
				"subtotalid": "",
				"value": "",
				"input": "datetime",
				"mode": "readonly"
			},
			{
				"label": "Date Updated",
				"field": "onsbookingpackage_updated",
				"subtotalid": "",
				"value": "",
				"input": "datetime",
				"mode": "readonly"
			},
			{
				"label": "Status",
				"field": "packagestatus_name",
				"subtotalid": "",
				"value": "",
				"input": "picker",
				"mode": "readonly"
			},
		],
		"footerlist": []
	},
	{
		"subformid": "item",
		"label": "Items",
		"table": "onsbookingitem",
		"sort": "onsbookingitem_id",
		"mobilerowtitlefieldidx":[2],
		"minrow": 1,
		"maxrow": 1,
		"allowdel": false,
		"fieldlist": [
			{
				"label": "Item",
				"field": "onsproduct_name",
				"subtotalid": "",
				"value": "",
				"input": "picker",
				"mode": "readonly"
			},
			{
				"label": "Active",
				"field": "onsbookingitem_active",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "required"
			},
			{
				"label": "Product Active",
				"field": "onsproduct_active",
				"subtotalid": "",
				"value": "",
				"input": "hidden",
				"mode": "readonly"
			},
			{
				"label": "Price/Unit",
				"field": "#onsproduct_price",
				"subtotalid": "",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			},
			{
				"label": "Order QTY",
				"field": "onsbookingitem_qty",
				"subtotalid": "orderpcs",
				"destfield": "",
				"value": "",
				"input": "integer",
				"mode": "required"
			},
			{
				"label": "Order Subtotal",
				"field": "onsbookingitem_qty*onsproduct_price*onsbookingitem_active",
				"subtotalid": "orderamount",
				"value": "",
				"input": "currency",
				"mode": "required"
			},
			{
				"label": "Available QTY",
				"field": "onsbookingitem_pickqty",
				"subtotalid": "availableorderpcs",
				"value": "",
				"input": "integer",
				"mode": "required"
			},
			{
				"label": "Available Subtotal",
				"field": "onsbookingitem_pickqty*onsproduct_price*onsbookingitem_active",
				"subtotalid": "availableorderamount",
				"value": "",
				"input": "currency",
				"mode": "required"
			},
			{
				"label": "Dispatched QTY",
				"field": "onsbookingitem_deliverqty",
				"subtotalid": "deliverorderpcs",
				"value": "",
				"input": "integer",
				"mode": "required"
			},
			{
				"label": "Dispatched Subtotal",
				"field": "onsbookingitem_deliverqty*onsproduct_price*onsbookingitem_active",
				"subtotalid": "deliverorderamount",
				"value": "",
				"input": "currency",
				"mode": "required"
			},
			{
				"label": "Notes",
				"field": "onsbookingitem_notes",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			},
		],
		"footerlist": [
			{
				"label": "Fees",
				"field": "onsbooking_fees",
				"subtotalid": "",
				"value": "",
				"input": "currency",
				"mode": "required"
			},
			{
				"label": "Ordered Total",
				"field": "onsbooking_fees+orderamount",
				"subtotalid": "",
				"destfield": "onsbooking_totalamount",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			},
			{
				"label": "Available Total",
				"field": "onsbooking_fees+availableorderamount",
				"subtotalid": "",
				"destfield": "onsbooking_availableamount",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			},
			{
				"label": "Dispatched Total",
				"field": "onsbooking_fees+deliverorderamount",
				"subtotalid": "",
				"destfield": "onsbooking_deliveramount",
				"value": "",
				"input": "currency",
				"mode": "readonly"
			},
		]
	}
];


const PACKAGESTATUS_TRANSIT = 2; // internal logistics
const PACKAGESTATUS_STORED = 3;

const PACKAGESTATUS_CUSTOMER = 5; // Released to customer/courier


const OnsbookingPage = ({location}) => {
	const [token, setToken] = useState("");
	const [userinfo, setUserinfo] = useState({});
	const [pagetitle, setPagetitle] = useState("");
	const [documentid, setDocumentid] = useState(0);
	const [docstatusid, setDocstatusid] = useState(0);
	const [locationid, setLocationid] = useState(0);
	const [locationname, setLocationname] = useState("");
	const [onsorderformid, setOnsorderformid] = useState(0);
	const [fulfilldate, setFulfilldate] = useState("");
	const [nnpickupid, setNnpickupid] = useState(0);
	const [nndropoffid, setNndropoffid] = useState(0);
	const [onsorderformstatusid, setOnsorderformstatusid] = useState(0);
	const [onsorderformfulfillmentid, setoOsorderformfulfillmentid] = useState(0);
	const [numpackage, setNumpackage] = useState(0);

	useEffect(()=> {
		// Handle Change on the parent side
		if (token.length > 0) {
			negosyonowAPI.getUserLocation(token, function(id, name) {
				setLocationid(id);
				setLocationname(name);
			});
		}
	}, [token]);


	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		var nnpickupalias_id = CUSTOMDELIVERYDROPOFFID;
		var packagestatus_id = 1; // Packed
		var tmpnotes = "";

		var curfulfilldate = fulfilldate;
		var curdropoffid = nndropoffid;
		//var pkid = 0;
		var result = "Updated";
		//var customparam = {};
		const updatetimestr = formatTools.getDateStr();
		var customparam = JSON.parse(JSON.stringify(params));
		var itempacked = false;
		/*
		if (params.hasOwnProperty("documentid")) {
			pkid = params.documentid;
		}
		*/

		customparam.onsbooking_updated = updatetimestr;
		if ((curdropoffid < 1 || curdropoffid === CUSTOMDELIVERYDROPOFFID) && locationid > 0) {
			// No dropoff value set and co-located user
			customparam.nndropoff_id = locationid;
		}

		if (customparam.hasOwnProperty("onsbooking_targetdropoffdate")) {
			if (customparam.onsbooking_targetdropoffdate) {
				curfulfilldate = customparam.onsbooking_targetdropoffdate;
				if (curdropoffid === CUSTOMDELIVERYDROPOFFID) {
					// Target Date will be set to delivery date
					customparam.onsbooking_targetdeliverydate = customparam.onsbooking_targetdropoffdate;
				}
				// Otherwise target Delivery Date to be set by courier
			}
		}

		if (customparam.hasOwnProperty("onsbookingstatus_id")) {
			if (customparam.onsbookingstatus_id) {
				var tmpstatusid = parseInt(customparam.onsbookingstatus_id, 10);
				if (ALLOCATEDSTATUSID === tmpstatusid) {
					// Allocated, check if needs scheduling
					if ((locationid > 0 && nnpickupid === locationid) || (curdropoffid === nnpickupid)) {
						// Co-located and same pickup point or drop off to pick up point
						customparam.onsbooking_targetdeliverydate = curfulfilldate;
						result = "For Customer Approval";
					} else {
						customparam.onsbookingstatus_id = 9; // For scheduling
						customparam.onsbooking_targetdeliverydate = ""; // Reset date, in case it was rescheduled
						result = "For Scheduling";
					}
				} else if (2 === tmpstatusid) {
					// Dispatched
					if (locationid > 0) {
						nnpickupalias_id=locationid; // Co-located
						packagestatus_id = PACKAGESTATUS_STORED;
						if (nnpickupid === locationid) {
							customparam.onsbooking_takeout = 1;
							tmpnotes = "Ready for Pick up";
						} else {
							tmpnotes = "Received by Hub";
						}
					} else {
						tmpnotes = "Packed by Seller";
					}
					result = tmpnotes;
					customparam.onsbooking_locationnotes = tmpnotes;
					customparam.nnpickupalias_id = nnpickupalias_id;
					customparam.onsbooking_locationdate = updatetimestr;
					customparam.packagestatus_id=packagestatus_id;
					itempacked = true;
				}
			}
		}

		if (customparam.hasOwnProperty("subform")) {
			var nextpackageid = numpackage+1;
			var subformidx = 0;
			var rowidx = 0;
			var rowpkid = 0;
			while (subformidx < customparam.subform.length) {
				if (customparam.subform[subformidx].table.toLowerCase() === "onsbookingpackage") {
					rowidx = 0;
					while (rowidx < customparam.subform[subformidx].rowdata.length) {
						rowpkid = parseInt(customparam.subform[subformidx].rowdata[rowidx].onsbookingpackage_id, 10);
						if (isNaN(rowpkid)) {
							rowpkid = 0;
						}
						if (rowpkid === 0 || customparam.subform[subformidx].rowdata[rowidx].hasOwnProperty("onsbookingpackage_name")) {
							customparam.subform[subformidx].rowdata[rowidx].onsbookingpackage_updated = updatetimestr;
						}
						if (rowpkid === 0) {
							customparam.subform[subformidx].rowdata[rowidx].onsbookingpackage_created = updatetimestr;
							customparam.subform[subformidx].rowdata[rowidx].onsbookingpackage_packagenum = nextpackageid;
							nextpackageid++;
						}
						rowidx++;
					}
				}
				subformidx++;
			}
		}
		//callback({"status":"Error"}); return;

		webappsAPI.savePayload(entity, customparam, token, payloadlist, subformpayloadlist, function(response){
			if (response.status === "OK") {
				handlePackageHistory(response.documentid, token, itempacked, updatetimestr, nnpickupalias_id, packagestatus_id, tmpnotes, function(historyres) {
					triggerNotifications(response.documentid, onsorderformid, result, token, function() {
						callback(response);
					});
				}); // handlePackageHistory
			} else {
				callback(response);
			}
		});
	}

	function triggerNotifications(pkid, orderformid, result, token, callback)
	{
		negosyonowAPI.buildOrder(token, "order",{
			"notifysubject": result,
			"orderform": orderformid,
			"onsbooking": pkid,
			"action": "recomputeorder"
		}).then(nnresponse => {
			callback();
		});
	}

	function handlePackageHistory(pkid, token, itempacked, updatetimestr, nnpickup_id, packagestatus_id, notes, callback)
	{
		if (itempacked === false) {
			callback({"status":"OK", "message":"Not packed"});
			return;
		}

		var action = "";
		if (packagestatus_id === PACKAGESTATUS_STORED) {
			action = "Received";
		} else if (packagestatus_id === PACKAGESTATUS_TRANSIT) {
			action = "Released";
		} else if (packagestatus_id === PACKAGESTATUS_CUSTOMER) {
			action = "Dispatched/Released to Customer";
		} else {
			action = "Others";
		}
		webappsAPI.saveRecord("ONSBOOKINGLOCATIONHISTORY", {
				"onsbooking_id": pkid,
				"onsbookinglocationhistory_datetime": updatetimestr,
				"nnpickup_id": nnpickup_id,
				"onsbookinglocationhistory_action": action,
				"onsbookinglocationhistory_notes": notes,
				"onsbookinglocationhistory_user": userinfo.name
			}, token).then(historyresponse => {
				callback({"status":"OK", "message":"Recorded"});
			}); // add history
	}

	function customSetFormData(newformdata)
	{
		var newfulfilldate;
		var newpickupid = 0;
		var newdropoffid = 0;
		var newdocid = 0;
		var newnumpackage = 0;
		var neworderformid = 0;
		var newstatusid = 0;
		var newfulfillmentid = 0;

		if (newformdata.hasOwnProperty("onsbooking_id")) {
			if (newformdata.onsbooking_id) {
				newdocid = newformdata.onsbooking_id;
			}
		}
		if (newformdata.hasOwnProperty("onsorderform_id")) {
			if (newformdata.onsorderform_id) {
				neworderformid = newformdata.onsorderform_id;
			}
		}
		if (newformdata.hasOwnProperty("nndropoff_id")) {
			if (newformdata.nndropoff_id) {
				newdropoffid = newformdata.nndropoff_id;
			}
		}
		if (newformdata.hasOwnProperty("nnpickup_id")) {
			if (newformdata.nnpickup_id) {
				newpickupid = newformdata.nnpickup_id;
			}
		}
		if (newformdata.hasOwnProperty("onsbooking_targetdropoffdate")) {
			if (newformdata.onsbooking_targetdropoffdate) {
				newfulfilldate = newformdata.onsbooking_targetdropoffdate;
			}
		}

		//setFulfilldate
		if (newformdata.hasOwnProperty("onsbookingstatus_id")) {
			if (newformdata.onsbookingstatus_id) {
				newstatusid = newformdata.onsbookingstatus_id;
			}
		}
		if (newformdata.hasOwnProperty("onsorderformfulfillment_id")) {
			if (newformdata.onsorderformfulfillment_id) {
				newfulfillmentid = newformdata.onsorderformfulfillment_id;
			}
		}
		if (newformdata.hasOwnProperty("subform"))  {
			var subformidx = 0;
			while (subformidx < newformdata.subform.length) {
				if(subformFields[subformidx].table.toLowerCase() === "onsbookingpackage") {
					newnumpackage = newformdata.subform[subformidx].length;
					break;
				}
				subformidx++;
			}
		}
		setFulfilldate(newfulfilldate);
		setNnpickupid(newpickupid);
		setNndropoffid(newdropoffid);
		setNumpackage(newnumpackage);
		setDocstatusid(newstatusid);
		setDocumentid(newdocid);
		setOnsorderformid(neworderformid);
		setoOsorderformfulfillmentid(newfulfillmentid);

		if (neworderformid > 0) {
			webappsAPI.queryData("onsorderform", {
				fieldlist:["onsorderformstatus_id"],
				condlist: ["onsorderform_id="+neworderformid]
			}, token).then((response)=> {
				var newstatusid = 2; // Ordered
				if (response.status === "OK") {
					if (response.records.length > 0) {
						newstatusid = response.records[0].onsorderformstatus_id;
					}
				}
				setOnsorderformstatusid(newstatusid); // Ordered
			});
		} else {
			setOnsorderformstatusid(2); // Ordered
		}
	}


	function findSubformIdxByTablename(tablename)
	{
		var subformidx = 0;
		while (subformidx < subformFields.length) {
			if (subformFields[subformidx].table === tablename) {
				return subformidx;
			}
			subformidx++;
		}
		return -1;
	}

	function subformValidateQTYValues(newstatusid, itemsubform)
	{
		var rowidx = 0;
		var colidx = 0;
		var totalfound = 0;
		var allocatedfound = 0;
		var isactive = false;
		while (rowidx < itemsubform.length) {
			isactive = false;
			colidx = 0;
			while (colidx < itemsubform[rowidx].length) {
				if (itemsubform[rowidx][colidx].field === "onsbookingitem_active") {
					if (parseInt(itemsubform[rowidx][colidx].value,10)>0) {
						isactive = true;
					}
				}
				colidx++;
			}
			if (isactive) {
				colidx = 0;
				while (colidx < itemsubform[rowidx].length) {
					if (itemsubform[rowidx][colidx].field === "onsbookingitem_pickqty" || itemsubform[rowidx][colidx].field === "onsbookingitem_deliverqty") {
						if (parseInt(itemsubform[rowidx][colidx].value,10) < 0) {
							return {"status":"Error", "message":"Invalid QTY Value"};
						}
						if (itemsubform[rowidx][colidx].field === "onsbookingitem_pickqty") {
							allocatedfound = allocatedfound + parseInt(itemsubform[rowidx][colidx].value,10);
						} else if (itemsubform[rowidx][colidx].field === "onsbookingitem_deliverqty") {
							totalfound = totalfound + parseInt(itemsubform[rowidx][colidx].value,10);
							if ((newstatusid !== DISPATCHSTATUSID && newstatusid !== PACKEDSTATUSID) && totalfound>0) {
								return {"status":"Error", "message":"Please set Dispatched QTY to 0 while document is not being packed/dispatched"};
							}
						}
					}
					colidx++;
				}
			}

			rowidx++;
		}
		if (allocatedfound === 0 && newstatusid === ALLOCATEDSTATUSID) {
			return {"status":"Error", "message":"No Available QTY found"}
		}
		if (totalfound === 0 && (newstatusid === DISPATCHSTATUSID || newstatusid === PACKEDSTATUSID)) {
			return {"status":"Error", "message":"No Dispatched QTY found"}
		}
		return {"status":"OK"};
	}

	function subformGetNewPackages(packagesubform)
	{
		var rowidx = 0;
		var colidx = 0;
		var totalfound = 0;
		while (rowidx < packagesubform.length) {
			colidx = 0;
			while (colidx < packagesubform[rowidx].length) {
				if (packagesubform[rowidx][colidx].field === "onsbookingpackage_id") {
					if (parseInt(packagesubform[rowidx][colidx].value,10)<1) {
						totalfound++;
					}
				}
				colidx++;
			}
			rowidx++;
		}
		return totalfound;
	}

	function formValidateValues(mainform, subformlist, subformfooterlist)
	{
		var newstatusid  = 0;
		var idx = 0;
		var nndropoff_id = 0;
		var onsbooking_targetdropoffdate = "";
		while (idx < mainform.length) {
			if (mainform[idx].field === "onsbookingstatus_id") {
				newstatusid = parseInt(mainform[idx].value,10);
			} else if (mainform[idx].field === "nndropoff_id") {
				nndropoff_id = parseInt(mainform[idx].value,10);
				setNndropoffid(nndropoff_id);
				if ((nndropoff_id < 1||nndropoff_id===CUSTOMDELIVERYDROPOFFID) && locationid > 0) {
					nndropoff_id = locationid;
				}
			} else if (mainform[idx].field === "onsbooking_targetdropoffdate") {
				onsbooking_targetdropoffdate = mainform[idx].value;
			}
			idx++;
		}

		if (newstatusid === ALLOCATEDSTATUSID) {
			if (onsbooking_targetdropoffdate.length < 1 || nndropoff_id < 1 || nndropoff_id === CUSTOMDELIVERYDROPOFFID) {
				return {"status":"Error", "message":"Please provide drop off and target date"};
			} else if (onsorderformfulfillmentid !== 2 && nndropoff_id === CUSTOMDELIVERYDROPOFFID) {
				// CUSTOMDELIVERYDROPOFFID is for delivery only
				return {"status":"Error", "message":"N/A Drop off for custom delivery only"};
			}
		}

		var subformidx = findSubformIdxByTablename("onsbookingitem");
		if (subformidx>=0) {
			var tmpres = subformValidateQTYValues(newstatusid, subformlist[subformidx]);
			if (tmpres.status !== "OK") {
				return tmpres;
			}
		}
		if (newstatusid === DISPATCHSTATUSID || newstatusid === PACKEDSTATUSID) {
			subformidx = findSubformIdxByTablename("onsbookingpackage");
			if (numpackage + subformGetNewPackages(subformlist[subformidx]) < 1) {
				return {"status":"Error", "message":"Please specify packages"};
			}
		}

		return {"status":"OK"};
	}

	function customEditFieldInfo(inputlist, idx)
	{
		var tmpfield = {};
		if (onsorderformstatusid === 5 || onsorderformstatusid === 6) {
			// 5: Completed, 6: Cancelled
			tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
			if (inputlist[idx].field === "onsbookingstatus_id") {
				// Add note if not the correct booking status
				if (onsorderformstatusid === 5) {
					if (parseInt(tmpfield.value, 10) !== 3) {
						tmpfield.info = "Order Completed";
						tmpfield.value = 3;
					}
				} else if (onsorderformstatusid === 6) {
					if (parseInt(tmpfield.value, 10) !== 5) {
						tmpfield.info = "Order Cancelled";
						tmpfield.value = 5;
					}
				}
			}
			tmpfield.mode = "readonly";
			return tmpfield;
		}
		if (inputlist[idx].field === "onsbookingstatus_id") {
			tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
			if (docstatusid === 1) {
				tmpfield.options = [
					{"value":1,"display":"Ordered"},
					{"value":4,"display":"Allocated/Scheduled"},
					// TODO: Remove these option when live
					{"value":8,"display":"Packed"},
					{"value":2,"display":"Dispatched"}
				];
			} else if (docstatusid === 4) {
				// TODO: Remove this section when live
				tmpfield.options = [
					{"value":4,"display":"Allocated/Scheduled"},
					{"value":7,"display":"Acceped/For dispatching"},
					{"value":6,"display":"For re-scheduling"},
					{"value":8,"display":"Packed"},
					{"value":2,"display":"Dispatched"}
				];
			} else if (docstatusid === 6) {
				tmpfield.options = [
					{"value":4,"display":"Allocated/Scheduled"},
					{"value":6,"display":"For re-scheduling"}
				];
			} else if (docstatusid === 7) {
				tmpfield.options = [
					{"value":7,"display":"Acceped/For dispatching"},
					{"value":8,"display":"Packed"},
					{"value":2,"display":"Dispatched"}
				];
			} else if (docstatusid === 8) {
				tmpfield.options = [
					{"value":8,"display":"Packed"},
					{"value":2,"display":"Dispatched"}
				];
			} else {
				tmpfield.mode = "readonly";
			}
			return tmpfield;
		} else if (inputlist[idx].field === "onsbookingitem_qty") {
			tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
			tmpfield.mode = "readonly";
			return tmpfield;
		} else if (inputlist[idx].field === "onsbookingitem_pickqty") {
			if (docstatusid !== 1 && docstatusid !== 6) {
				// not ordered / for rescheduling
				tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
				tmpfield.mode = "readonly";
				return tmpfield;
			}
		} else if (inputlist[idx].field === "onsbookingitem_deliverqty" || inputlist[idx].field === "onsbookingitem_notes" || inputlist[idx].field === "onsbooking_fees") {
			if (docstatusid === 2 || docstatusid === 3 || docstatusid === 5 || docstatusid === 8) {
				// Dispatched-2, Packed-8, completed-3 or cancelled-5
				tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
				tmpfield.mode = "readonly";
				return tmpfield;
			} else if (inputlist[idx].field === "onsbookingitem_notes") {
				var warningidx = 0;
				var softwarningtext = "";
				while (warningidx < inputlist.length) {
					if (inputlist[warningidx].field === "onsproduct_active") {
						if (parseInt(inputlist[warningidx].value, 10) === 0) {
							softwarningtext = softwarningtext + ";Item Marked Unavailable";
						}
					}
					warningidx++;
				} // while inputlist

				if (softwarningtext.length > 0) {
					tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
					var dividerchar = "";
					if (!tmpfield.hasOwnProperty("info")) {
						tmpfield.info = "";
					} else if (!tmpfield.info) {
						tmpfield.info = "";
					} else {
						dividerchar = ";";
					}
					if (softwarningtext.length > 0) {
						tmpfield.info = tmpfield.info+dividerchar+softwarningtext.substring(1);
					}
					return tmpfield;
				}
			} else {

				if (docstatusid !== 7 && false) {
					// TODO Activate this when live
					// Not accepted for dispatching yet
					tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
					tmpfield.mode = "readonly";
					return tmpfield;
				} else if (docstatusid === 6) {
					// TODO Remove this when live (6 - for rescheduling)
					tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
					tmpfield.mode = "readonly";
					return tmpfield;
				}
			}
		} else if (inputlist[idx].field === "nndropoff_name" || inputlist[idx].field === "onsbooking_targetdropoffdate") {
			if (locationid > 0 && inputlist[idx].field === "nndropoff_name") {
				// Co-located
				tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
				if ((nndropoffid < 1 || nndropoffid === CUSTOMDELIVERYDROPOFFID)) {
					tmpfield.value = locationname;
				}
				tmpfield.mode = "readonly";
				return tmpfield;
			}
			if (docstatusid !== 1 && docstatusid !== 6) {
				// Not Ordered or not for rescheduling
				tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
				tmpfield.mode = "readonly";
				return tmpfield;
			}
		} else if (inputlist[idx].field === "onsbookingitem_active") {
			tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
			tmpfield.mode = "readonly";
			return tmpfield;
		} else if (inputlist[idx].field === "onsaddress_name") {
			tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
			tmpfield.mode = "readonly";

			if (onsorderformfulfillmentid !== 2) {
				tmpfield.value = "N/A (Not for Delivery)";
			}
			return tmpfield;
		}
		return inputlist[idx];

	}


	function customFieldFragment(inputlist, idx)
	{
		if (inputlist[idx].field === "onsbookingpackage_qrcode") {
			if (docstatusid === DISPATCHSTATUSID || docstatusid === PACKEDSTATUSID) {
				const reftime = (new Date("2020-01-01 00:00:00")).getTime();
				var tmpidx = 0;
				var qrdata = {z:"bookingp",d:documentid};
				if (numpackage > 1) {
					qrdata.t = numpackage;
				}
				while (tmpidx < inputlist.length) {
					if (inputlist[tmpidx].field === "onsbookingpackage_updated") {
						qrdata.u = Math.floor(((new Date(inputlist[tmpidx].value)).getTime()-reftime)/1000).toString(16);
					} else if (inputlist[tmpidx].field === "onsbookingpackage_name") {
						qrdata.l = inputlist[tmpidx].value;
					} else if (inputlist[tmpidx].field === "onsbookingpackage_packagenum") {
						if (inputlist[tmpidx].value === 0) {
							return <></>
						}
						if (numpackage > 1) {
							qrdata.n = inputlist[tmpidx].value;
						}
					}
					tmpidx++;
				}
				return <div className="text-centered noprint">
						(click to zoom)<br/>
						<div style={{"width":"4vw", "display":"inline-block"}}><QRGenerator correctionlevel={"H"} sizepx={256} data={webappsAPI.encodePayload(JSON.stringify(qrdata))} /></div>
					</div>
			}
			return <>(Not yet packed/dispatched)</>;
		} else if (inputlist[idx].field === "onsbookingpackage_packagenum") {
			if (docstatusid === DISPATCHSTATUSID || docstatusid === PACKEDSTATUSID) {
				if (inputlist[idx].value === 0) {
					return <></>;
				}
				return null;
			}
			return <></>;
		} else if (inputlist[idx].field === "onsbookingpackage_name") {
			if (docstatusid === DISPATCHSTATUSID || docstatusid === PACKEDSTATUSID) {
				return <>{inputlist[idx].value}</>
			}
		} else if (inputlist[idx].field === "onsaddress_name") {
			if (onsorderformfulfillmentid !== 2) {
				return <></>;
			}
		}
		// default
		return null;
	}

	return <Layout fullPath={location.pathname}
			newUserHandler={(newtoken, newuserinfo)=>{setToken(newtoken); setUserinfo(newuserinfo)}}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			privatePage={true} usegatedcontent={true}>
			<WebappsForm
				location={location}
				allowadd={false}
				allowdelete={false}
				allowprint={docstatusid === PACKEDSTATUSID || docstatusid === DISPATCHSTATUSID}
				pagetitle={pagetitle}
				mobilerowtitlefield={["onsorderform_code","onsbooking_datetime"]}
				userSearchParam={{"orderby":"onsbooking_datetime desc", "joincond": "ONSORDERFORM.onsorderform_id=ONSBOOKING.onsorderform_id and ONSBRANCH.onsbranch_id=ONSBOOKING.onsbranch_id and ONSORDERFORMFULFILLMENT.onsorderformfulfillment_id=ONSBOOKING.onsorderformfulfillment_id and ONSBOOKINGSTATUS.onsbookingstatus_id=ONSBOOKING.onsbookingstatus_id and ONSCUSTOMER.onscustomer_id=ONSBOOKING.onscustomer_id"}}
				searchFields={searchFields}

				//userEditJoincond="ONSORDERFORM.onsorderform_id=ONSBOOKING.onsorderform_id and ONSBRANCH.onsbranch_id=ONSBOOKING.onsbranch_id and ONSBOOKINGSTATUS.onsbookingstatus_id=ONSBOOKING.onsbookingstatus_id"

				customSubmit={customSubmit}
				customSetFormData={customSetFormData}
				customEditFieldInfo={customEditFieldInfo}
				customFieldFragment={customFieldFragment}
				editFields={formFields}
				editSubFormFields={subformFields}
				customValidateValues={formValidateValues}
				entity={ENTITYID}
				token={token} />
		</Layout>

}


export default OnsbookingPage;
